import { ParsedUrlQueryInput } from "querystring";
import { DISCORD_URL, GITHUB_URL, LIBUM_FEEDBACK, LINKEDIN_URL } from "shared/constants/globals";

export type NavigationItem = {
  id: string;
  name: string;
  description?: string;
  icon?: NavigationIcon;
  link?: string;
  params?: ParsedUrlQueryInput;
  children?: NavigationItem[];
}

export type NavigationOption = {
  name: string;
  description?: string;
  icon?: NavigationIcon;
  link?: string;
  params?: ParsedUrlQueryInput;
  children?: NavigationOption[];
}

export interface NavigationIcon {
  name: string;
  type: string;
}

const HEADER_POPUP_OPTIONS: NavigationOption[] = [{
  name: 'PowerOn Pro',
  children: [
    { name: 'Overview', icon: { name: 'browser', type: 'eva' },  link: '/poweron-pro' },
    { name: 'Features', icon: { name: 'grid', type: 'eva' },  link: '/poweron-pro#features' },
    { name: 'Offerings', icon: { name: 'gift', type: 'eva' },  link: '/poweron-pro#offerings' },
  ]
}, {
  name: 'NexumAPI',
  children: [
    { name: 'Overview', icon: { name: 'code', type: 'eva' },  link: '/nexum' },
    { name: 'Features', icon: { name: 'grid', type: 'eva' },  link: '/nexum#features' },
    { name: 'Offerings', icon: { name: 'gift', type: 'eva' },  link: '/nexum#offerings' },
  ]
}, {
  name: 'Glaci',
  children: [
    { name: 'Overview', icon: { name: 'monitor', type: 'eva' },  link: '/glaci' },
    { name: 'Features', icon: { name: 'grid', type: 'eva' },  link: '/glaci#features' },
    { name: 'Inspiration', icon: { name: 'bulb', type: 'eva' },  link: '/glaci#inspiration' },
    { name: 'Offerings', icon: { name: 'gift', type: 'eva' },  link: '/glaci#offerings' },
  ]
}, {
  name: 'Company',
  children: [
    { name: 'About', icon: { name: 'browser', type: 'eva' },  link: '/about#overview' },
    { name: 'Leadership', icon: { name: 'people', type: 'eva' },  link: '/about#founders' },

  ],
}, {
  name: 'Resources',
  children: [
    { name: 'Blog', icon: { name: 'book-open', type: 'eva' },  link: '/blog' },
    { name: 'Discord', icon: { name: 'discord', type: 'icons8' },  link: DISCORD_URL },
    { name: 'GitHub', icon: { name: 'github', type: 'icons8' },  link: GITHUB_URL },
    { name: 'Legal', icon: { name: 'shield', type: 'eva' },  link: '/legal' },
    { name: 'LinkedIn', icon: { name: 'linkedin', type: 'icons8' },  link: LINKEDIN_URL },
    { name: 'Feedback', icon: { name: 'message-square', type: 'eva' },  link: LIBUM_FEEDBACK },
  ]
}];

/**
 * Assigns unique ids for navigation items
 * @param options The navigation options
 * @param depth The navigation depth
 * @returns The navigation items
 */
 const createNavigationItems = (options: NavigationOption[], depth = 0): NavigationItem[] => {
  return options.map((option, idx) => ({
    ...option,
    id: depth === 0 ? idx.toString() : `${depth}.${idx}`,
    children: option.children ? createNavigationItems(option.children, depth + 1) : undefined,
  }));
}

export const HEADER_POPUP_ITEMS: NavigationItem[] = createNavigationItems(HEADER_POPUP_OPTIONS);

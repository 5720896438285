import Footer from './footer';
import Header from './header';

import { ContentContainer, LayoutContainer } from './layout.styles';

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Layout({ children, className }: LayoutProps) {
  return (
    <LayoutContainer className={className}>
      <Header />
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  );
}

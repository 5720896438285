import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { ThemeProvider } from 'shared/styles/theme';
import { ToastContainer } from 'react-toastify';

import '@fontsource/palanquin/300.css';
import '@fontsource/palanquin/400.css';
import '@fontsource/palanquin/500.css';
import '@fontsource/palanquin/600.css';
import '@fontsource/palanquin/700.css';
import '@fontsource/palanquin-dark/400.css';
import '@fontsource/palanquin-dark/500.css';
import '@fontsource/palanquin-dark/600.css';
import '@fontsource/palanquin-dark/700.css';

import Layout from '../components/layout';
import { GlobalStyle } from 'shared/styles/global-style';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <Layout>
        <Script src="/scripts/bodymovin.min.js" />
        <Component {...pageProps} />
        <ToastContainer />
      </Layout>
    </ThemeProvider>
  );
}

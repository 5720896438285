import Link from 'next/link';
import {
  HeaderPopupClose,
  HeaderPopupContainer,
  HeaderPopupContent,
  HeaderPopupContentContainer,
  HeaderPopupFooter,
  HeaderPopupFooterLinkDefault,
  HeaderPopupMenu,
  HeaderPopupSection,
  HeaderPopupSectionLinks,
  HeaderPopupSectionTitle,
} from './header-popup.styles';
import { useRef, useState } from 'react';
import { PrimaryLink } from 'shared/styles/components';
import { handleExternallLink } from 'shared/constants/utilities';
import { LIBUM_PORTAL } from 'shared/constants/globals';
import { HEADER_POPUP_ITEMS } from 'shared/data/nav-popup';

import Icon from 'shared/components/icon';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';

import CLOSE from 'public/icons/eva/close.svg';
import MENU from 'public/icons/eva/menu.svg';
import HOVER_ARROW from 'public/icons/hover_arrow.svg';

export default function HeaderPopup({}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const togglePopup = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  return (
    <HeaderPopupContainer ref={ref}>
      <Link href="" aria-label="Libum mobile menu" passHref legacyBehavior>
        <HeaderPopupMenu onClick={togglePopup}>
          <MENU />
        </HeaderPopupMenu>
      </Link>
      <HeaderPopupContentContainer $isVisible={isActive}>
        <HeaderPopupContent className={isActive ? 'active' : ''}>
          <HeaderPopupClose onClick={togglePopup}>
            <CLOSE />
          </HeaderPopupClose>
          {HEADER_POPUP_ITEMS.map((item, idx) => (
            <HeaderPopupSection className={idx === HEADER_POPUP_ITEMS.length - 1 ? 'last' : ''} key={item.id}>
              <HeaderPopupSectionTitle className="popup-title">{item.name}</HeaderPopupSectionTitle>
              <HeaderPopupSectionLinks>
                {item.children?.map((child) => (
                  <Link
                    href=""
                    key={child.id}
                    onClick={(e) => {
                      setIsActive(false);
                      handleExternallLink(e, child?.link || '');
                    }}
                  >
                    {child.icon && (
                      <Icon
                        name={child.icon.name}
                        type={child.icon.type}
                        className={child.icon.type == 'eva' || child.icon.type == 'icons8' ? 'generic' : ''}
                      />
                    )}
                    <span className="text-overflow">{child.name}</span>
                  </Link>
                ))}
              </HeaderPopupSectionLinks>
            </HeaderPopupSection>
          ))}
          <HeaderPopupFooter>
            <HeaderPopupFooterLinkDefault href="/docs" target="_self">
              Docs
            </HeaderPopupFooterLinkDefault>
            <PrimaryLink href={LIBUM_PORTAL}>
              Sign In
              <HOVER_ARROW />
            </PrimaryLink>
          </HeaderPopupFooter>
        </HeaderPopupContent>
      </HeaderPopupContentContainer>
    </HeaderPopupContainer>
  );
}

import styled from 'styled-components';
import { devices } from 'shared/styles/components';

export const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 64px 24px;
  height: calc(100vh - 128px);

  @media ${devices.laptopSMax} {
    height: 100%;
  }
`;

export const LandingBody = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const LandingColumns = styled.div`
  display: flex;
  flex: 1;
  gap: 48px;
  align-items: stretch;

  &.ltr {
    flex-direction: row;
  }
  &.rtl {
    flex-direction: row-reverse;
  }

  @media ${devices.laptopSMax} {
    &.ltr {
      flex-direction: column;
    }
    &.rtl {
      flex-direction: column-reverse;
    }
  }

  @media ${devices.mobileMax} {
    &.ltr {
      flex-direction: column-reverse;
    }
    &.rtl {
      flex-direction: column;
    }
  }
`;

export const LandingColumn = styled.div`
  user-select: none;

  &.left {
    flex: 0 0 auto;

    .image-container {
      position: relative;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 12px;

      img {
        position: relative;
        z-index: 2;
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);

        &.poweron-pro {
          max-width: 95%;
          height: 100%;
        }
        &.symx {
          margin-right: 32px;
          margin-bottom: 140px;
          max-width: 95%;
          height: 100%;

          @media ${devices.mobileMax} {
            margin-right: 32px;
            margin-bottom: 70px;
          }
        }
        &.naas {
          position: absolute;
          right: 0;
          bottom: -16px;
          max-width: 65%;
          height: fit-content;

          @media ${devices.mobileMax} {
            right: -24px;
          }
        }
      }
      .dot-mask {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        height: calc(100% + 2 * 72px);
        width: min(calc(100% + 192px), 100vw);
        background-image: radial-gradient(${({ theme }) => theme.colors.radialColor} 1px, transparent 1px);
        background-size: 16px 16px;
        -webkit-mask-image: radial-gradient(ellipse 50% 50% at 50% 50%, #000 90%, transparent 100%);
        mask-image: radial-gradient(ellipse 50% 50% at 50% 50%, #000 90%, transparent 100%);
      }

      @media ${devices.mobileMax} {
        align-items: center;

        .glaci-toggle {
          display: none;
        }
      }
    }
  }

  &.right {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    max-width: 400px;
    z-index: 2;

    &.nexum-api {
      @media ${devices.miniMobileMax} {
        min-height: 600px;
      }
    }

    @media ${devices.laptopSMax} {
      min-height: 500px;
    }
  }
`;

export const LandingRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 48px;
    font-family: ${({ theme }) => theme.fontFamily.display};
    font-weight: 600;
    user-select: none;
  }

  p {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 20px;
    line-height: 1.5;
    user-select: none;
  }

  .landing-button {
    display: flex;
    align-items: center;
    gap: 4px;
    transition: transform 0.3s ease;

    &.install {
      background-color: ${({ theme }) => theme.colors.success};
    }

    &.request,
    &.join {
      gap: 4px;
    }

    &.request svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      transform: scale(1.1);
    }

    svg {
      fill: ${({ theme }) => theme.colors.white};
      width: 16px;
      height: 16px;
    }
  }
`;

export const LandingAnnouncement = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 24px;
  text-align: left;

  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    line-height: 1;

    &:hover {
      color: ${({ theme }) => theme.colors.textTertiary};
      text-decoration: none;
    }
  }

  .tag {
    margin-top: 2px;
    padding: 0 5px;
    letter-spacing: 0.5px;
    line-height: 1.78;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
    border-radius: 4px;

    &.new {
      color: ${({ theme }) => theme.colors.textTertiary};
      border: 1px solid ${({ theme }) => theme.colors.textTertiary};
    }

    &.discover {
      color: ${({ theme }) => theme.colors.success};
      border: 1px solid ${({ theme }) => theme.colors.success};
    }

    &.learn {
      color: ${({ theme }) => theme.colors.warning};
      border: 1px solid ${({ theme }) => theme.colors.warning};
    }
  }
`;

export const LandingActions = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;

  a {
    margin: 0;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.textTertiary};
      text-decoration: none;
    }
  }
`;

export const LandingLogos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 4px;
  max-width: 90vw;
  user-select: none;

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.gray600};
    opacity: 0.75;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    span {
      text-transform: lowercase;
    }
  }

  .icons {
    display: flex;
    gap: 14px;
    align-items: center;

    svg {
      opacity: 0.75;
      fill: ${({ theme }) => theme.colors.gray600};

      .max_cu_svg__transparent {
        fill: ${({ theme }) => theme.colors.bgPrimary};
      }
    }
  }
`;

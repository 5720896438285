import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    position: absolute;
    padding: 0;
    margin: 0;
    min-height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    font-family: 'Palanquin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-rendering: optimizeLegibility;
  }

  html {
    scroll-padding-top: 93px;
  }

  * {
    margin: 0;
    box-sizing: border-box;
    letter-spacing: .01em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1.anchor > a,
  h2.anchor > a,
  h3.anchor > a {
    color: ${({ theme }) => theme.colors.textEmphasis};

    &:after {
      display: none;
      content: '#';
      margin-left: 0.25em;
      color: ${({ theme }) => theme.colors.textSecondary};
      width: auto;
      height: auto;
      background-color: transparent;
      font-weight: 400;
      font-family: ${({ theme }) => theme.fontFamily.display};
    }

    &:hover {
      text-decoration: none;

      &:after {
        display: inline-block;
      }
    }
  }

  /* Toastify */

  :root {
    --toastify-color-error: #FF5047;
    --toastify-color-info: #516AB2;
    --toastify-color-success: #00D68F;
    --toastify-color-warning: #FFBA44;
    --toastify-font-family: var(--font-display);
    --toastify-text-color-light: #291752;
    --toastify-toast-width: 350px;
  }

  /* Layout */

  :root {
    --page-max-width: 1260px;
    --page-max-width-med: 1024px;
    --page-grid-cols: 4;
    --page-gutter-padding: 24px;
    --page-section-padding: 56px;
  }

  @media screen and (min-width: 768px) {
    :root {
      --page-grid-cols: 8;
      --page-gutter-padding: 40px;
      --page-section-padding: 80px;
    }
  }

  @media screen and (min-width: 1024px) {
    :root {
      --page-grid-cols: 12;
      --page-section-padding: 120px;
    }
  }

  /* Typography */

  :root {
    --text-link: #4EC7E5;
    --lightness-factor: 1.1;
    --font-display: 'Palanquin';
    --font-headline: 'Palanquin Dark';
  }

  h1 {
    margin: 0;
    font-family: var(--font-headline);
    font-weight: 700;
    font-size: 44px;
    font-size: clamp(32px, 5vw, 44px);
    line-height: 95%;
    text-rendering: geometricPrecision
  }

  h2 {
    margin: 0;
    font-weight: 600;
    line-height: 120%;
    font-family: var(--font-headline);
    font-size: 32px;
    font-weight: 600;
    font-size: clamp(20px, 4vw, 40px)
  }

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 32px;
    font-size: clamp(18px, 3vw, 32px)
  }

  h3,
  h4 {
    line-height: 120%;
    font-family: var(--font-headline);
  }

  h4 {
    font-weight: 600;
    font-size: clamp(8px, 3vw, 16px)
  }

  h5 {
    font-family: var(--font-display);
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }

  .headline {
    font-family: var(--font-headline);
    font-weight: 700;
    line-height: 95%;
    text-rendering: geometricPrecision;
  }

  .text-xsmall {
    font-size: 14px;
    line-height: 18px;
  }

  .text-small {
    font-size: 16px;
    line-height: 24px;
  }

  .text-medium {
    font-size: 20px;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 140%;
  }

  .text-large {
    font-size: 24px;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 140%;
  }

  /* Hover arrow */

  .hover_arrow_svg__hover-arrow,
  .hover_arrow_small_svg__hover-arrow {
    --arrowSpacing: 8px;
    --arrowHoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
    --arrowHoverOffset: translateX(3px);
    --arrowTipTransform: none;
    --arrowLineOpacity: 0;
    position: relative;
    top: 1px;
    margin-left: var(--arrowSpacing);
    stroke-width: 2px;
    fill: none;
    stroke: currentColor;
  }

  .hover_arrow_svg__hover-arrow__small,
  .hover_arrow_small_svg__hover-arrow__small {
    --arrowSpacing: 4px;
    stroke-width: 1.5px;
  }

  .hover_arrow_svg__hover-arrow__linePath,
  .hover_arrow_small_svg__hover-arrow__linePath {
    opacity: var(--arrowLineOpacity);
    transition: opacity var(--arrowHoverTransition);
  }

  .hover_arrow_svg__hover-arrow__tipPath,
  .hover_arrow_small_svg__hover-arrow__tipPath {
    transform: var(--arrowTipTransform);
    transition: transform var(--arrowHoverTransition);
  }

  @media (pointer:fine) {
    a:hover .hover_arrow_svg__hover-arrow__linePath,
    button:hover .hover_arrow_svg__hover-arrow__linePath,
    li:hover .hover_arrow_small_svg__hover-arrow__linePath {
        --arrowLineOpacity: 1
    }
    a:hover .hover_arrow_svg__hover-arrow__tipPath,
    button:hover .hover_arrow_svg__hover-arrow__tipPath,
    li:hover .hover_arrow_small_svg__hover-arrow__tipPath {
        --arrowTipTransform: var(--arrowHoverOffset)
    }
  }

  @media (pointer:coarse) {
    a:active .hover_arrow_svg__hover-arrow__linePath,
    button:active .hover_arrow_svg__hover-arrow__linePath {
        --arrowLineOpacity: 1
    }
    a:active .hover_arrow_svg__hover-arrow__tipPath,
    button:active .hover_arrow_svg__hover-arrow__tipPath {
        --arrowTipTransform: var(--arrowHoverOffset)
    }
  }
`;

import Link from 'next/link';

import {
  HeaderActions,
  HeaderContainer,
  HeaderContent,
  HeaderNavigationContainer,
  HeaderLinks,
  HeaderSocial,
} from './header.styles';

import { PrimaryLink } from 'shared/styles/components';
import { LIBUM_PORTAL, LINKEDIN_LKG, LINKEDIN_URL } from 'shared/constants/globals';

import LIBUM_DARK from 'public/logos/libum_dark.svg';
import LIBUM_LIGHT from 'public/logos/libum_light.svg';
import HOVER_ARROW from 'public/icons/hover_arrow.svg';
import LINKEDIN from 'public/icons/icons8/linkedin.svg';
import SUN from 'public/icons/eva/sun.svg';
import MOON from 'public/icons/eva/moon.svg';
import { useRouter } from 'next/router';
import { useTheme } from 'shared/styles/theme';
import HeaderPopup from './header-popup';

type HeaderProps = {
  className?: string;
};

export default function Header({ className }: HeaderProps) {
  const { theme, toggleTheme } = useTheme();
  const router = useRouter();

  const isActive = (name: string): boolean => {
    return router.pathname.includes(name) ? true : false;
  };

  return (
    <nav className={className}>
      <HeaderContainer>
        <HeaderContent>
          <HeaderNavigationContainer>
            {/* Header logo */}
            <Link href="/" passHref legacyBehavior>
              <a style={{ display: 'flex' }} aria-label="Libum Home">
                {theme === 'dark' ? <LIBUM_DARK height={29} /> : <LIBUM_LIGHT height={29} />}
              </a>
            </Link>
            {/* Header tabs */}
            <HeaderLinks>
              <Link href="/poweron-pro" className={isActive('poweron-pro') ? 'active' : ''}>
                PowerOn Pro
              </Link>
              <Link href="/nexum" className={isActive('nexum') ? 'active' : ''}>
                NexumAPI
              </Link>
              <Link href="/glaci" className={isActive('glaci') ? 'active' : ''}>
                Glaci
              </Link>
              <Link href="/docs" className={isActive('docs') ? 'active' : ''}>
                Docs
              </Link>
            </HeaderLinks>
            {/* Header actions */}
            <HeaderActions>
              <HeaderSocial>
                <Link href={LINKEDIN_URL} target="_blank">
                  <LINKEDIN />
                  {/* @TODO: Eventually spend time on this, right now it doesn't matter */}
                  <span>{LINKEDIN_LKG}</span>
                </Link>
              </HeaderSocial>
              <Link href={LIBUM_PORTAL} passHref legacyBehavior>
                <PrimaryLink className="root-link" target="_blank">
                  Sign in
                  <HOVER_ARROW />
                </PrimaryLink>
              </Link>
              <div className="theme-toggle" onClick={toggleTheme}>
                {theme === 'dark' ? <MOON /> : <SUN />}
              </div>
              {/* Header mobile popup */}
              <HeaderPopup />
            </HeaderActions>
          </HeaderNavigationContainer>
        </HeaderContent>
      </HeaderContainer>
    </nav>
  );
}

import styled from 'styled-components';
import { devices } from 'shared/styles/components';

export const HeaderContainer = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: repeat(var(--page-grid-cols), 1fr);
  gap: 0 20px;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bgPrimaryAlpha};
  backdrop-filter: blur(8px);
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  z-index: 1000;
`;

export const HeaderContent = styled.div`
  display: flex;
  grid-column: span var(--page-grid-cols);
`;

export const HeaderNavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  cursor: default;

  & > a {
    padding: 20px;
    transition: color 0.2s;
    cursor: pointer;
    user-select: none;

    &.active,
    &:hover {
      color: ${(props) => props.theme.colors.textTertiary};
    }
  }

  @media ${devices.tabletMax} {
    display: none;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .theme-toggle {
    display: flex;
    cursor: pointer;

    &:hover svg {
      fill: ${(props) => props.theme.colors.textPrimary};
    }

    svg {
      fill: ${(props) => props.theme.colors.textSecondary};
      width: 20px;
      height: 20px;
      transition: fill 0.2s;
    }
  }

  @media ${devices.tabletMax} {
    gap: 16px;

    .theme-toggle {
      display: none;
    }
  }
`;

export const HeaderSocial = styled.div`
  display: flex;
  margin-left: 12px;

  a {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    height: 36px;
    line-height: 1;
    color: ${(props) => props.theme.colors.textSecondary};
    transition: color 0.2s;

    &:hover {
      color: ${(props) => props.theme.colors.textPrimary};

      svg {
        fill: ${(props) => props.theme.colors.textPrimary};
      }
    }

    svg {
      fill: ${(props) => props.theme.colors.textSecondary};
      width: 20px;
      height: 20px;
      transition: fill 0.2s;
    }
    span {
      margin-top: 1px;
      font-size: 14px;
      font-family: 'Anonymous Pro', monospace;
      line-height: 1;
    }
  }

  @media ${devices.tabletMax} {
    display: none;
  }
`;

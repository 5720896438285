import {
  FooterActions,
  FooterContainer,
  FooterContent,
  FooterCopyright,
  FooterLinks,
  FooterSocials,
} from './footer.styles';

import { DISCORD_URL, LINKEDIN_URL, GITHUB_URL, SALES_MAILTO, LIBUM_FEEDBACK } from 'shared/constants/globals';
import { getCurrentYear } from 'shared/constants/utilities';

import DISCORD from 'public/icons/icons8/discord.svg';
import FEEDBACK from 'public/icons/eva/message-square.svg';
import LINKEDIN from 'public/icons/icons8/linkedin.svg';
import GITHUB from 'public/icons/icons8/github.svg';
import Link from 'next/link';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterCopyright>
          ©&nbsp;{getCurrentYear()}
          <Link href="/company-information">Libum,&nbsp;LLC</Link>
        </FooterCopyright>
        <FooterActions>
          <FooterLinks>
            <Link href="/blog">Blog</Link>
            <Link href="/about">About</Link>
            <Link href="/legal">Legal</Link>
            <Link href={SALES_MAILTO}>Contact</Link>
          </FooterLinks>
          <FooterSocials>
            <Link href={DISCORD_URL} target="_blank" aria-label="Libum Discord">
              <DISCORD />
            </Link>
            <Link href={GITHUB_URL} target="_blank" aria-label="Libum GitHub">
              <GITHUB />
            </Link>
            <Link href={LINKEDIN_URL} target="_blank" aria-label="Libum LinkedIn">
              <LINKEDIN />
            </Link>
            <Link href={LIBUM_FEEDBACK} target="_blank" aria-label="Libum Feedback Portal">
              <FEEDBACK />
            </Link>
          </FooterSocials>
        </FooterActions>
      </FooterContent>
    </FooterContainer>
  );
}

import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

const lightTheme = {
  fontFamily: {
    display: `'Palanquin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    headline: `'Palanquin Dark', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
  },
  colors: {
    bgPrimary: '#EDF1F7',
    bgPrimaryAlpha: 'hsla(216, 38%, 95%, 80%)',
    bgSecondary: '#E4E9f2',
    bgSecondaryAlpha: 'hsla(219, 35%, 92%, 80%)',
    bgColor: '#516AB2',
    bgColorAlpha: 'hsla(225, 39%, 51%, 80%)',
    bgPopup: '#EDF1F7',
    bgPopupSecondary: '#E4E9f2',
    border: '#516AB2',
    borderSecondary: '#C5CEE0',
    radialColor: '#C5CEE0',
    textEmphasis: '#2E3A59',
    textPrimary: '#5C6880',
    textSecondary: '#76829A',
    textTertiary: '#516AB2',
    textQuaternary: '#4EC7E5',
    gray100: '#F7F9FC',
    gray200: '#EDF1F7',
    gray300: '#E4E9f2',
    gray400: '#C5CEE0',
    gray500: '#8F9BB3',
    gray600: '#76829A',
    gray700: '#5C6880',
    gray800: '#2E3A59',
    gray900: '#222B45',
    gray1000: '#192038',
    gray1100: '#151A30',
    gray1200: '#101426',
    white: '#FFF',
    error: '#FF5047',
    success: '#00D68F',
    warning: '#FFBA44',
  }
}

const darkTheme = {
  fontFamily: {
    display: `'Palanquin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    headline: `'Palanquin Dark', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
  },
  colors: {
    bgPrimary: '#2E3A59',
    bgPrimaryAlpha: 'hsla(223, 32%, 26%, 80%)',
    bgSecondary: '#222B45',
    bgSecondaryAlpha: 'hsla(225, 34%, 20%, 80%)',
    bgColor: '#516AB2',
    bgColorAlpha: 'hsla(225, 39%, 51%, 80%)',
    bgPopup: '#222B45',
    bgPopupSecondary: '#192038',
    border: '#516AB2',
    borderSecondary: '#2E3A59',
    radialColor: '#516AB2',
    textEmphasis: '#FFF',
    textPrimary: '#C5CEE0',
    textSecondary: '#8F9BB3',
    textTertiary: '#4EC7E5',
    textQuaternary: '#516AB2',
    gray100: '#F7F9FC',
    gray200: '#EDF1F7',
    gray300: '#E4E9f2',
    gray400: '#C5CEE0',
    gray500: '#8F9BB3',
    gray600: '#76829A',
    gray700: '#5C6880',
    gray800: '#2E3A59',
    gray900: '#222B45',
    gray1000: '#192038',
    gray1100: '#151A30',
    gray1200: '#101426',
    white: '#FFF',
    error: '#FF5047',
    success: '#00D68F',
    warning: '#FFBA44',
  }
}

type Theme = 'light' | 'dark';

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>('dark');

  useEffect(() => {
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    setTheme(systemTheme);

    const listener = (event: MediaQueryListEvent) => {
      setTheme(event.matches ? 'dark' : 'light');
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', listener);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <StyledThemeProvider theme={theme ==='light' ? lightTheme : darkTheme }>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

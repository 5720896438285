import { devices } from 'shared/styles/components';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(props) => props.theme.colors.textSecondary};
  background-color: ${(props) => props.theme.colors.bgPrimary};
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 48px;

  a {
    transition: color 0.2s;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: ${(props) => props.theme.colors.textTertiary};
    }
  }

  @media ${devices.laptopSMax} {
    align-items: flex-start;
  }
`;

export const FooterCopyright = styled.div`
  display: flex;
  gap: 4px;
  color: ${(props) => props.theme.colors.textSecondary};
  opacity: 0.8;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  user-select: none;

  @media ${devices.miniMobileMax} {
    flex-direction: column-reverse;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;

  @media ${devices.laptopSMax} {
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 24px;
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  @media ${devices.mobileMax} {
    gap: 12px;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    height: 24px;
  }
  svg {
    fill: ${(props) => props.theme.colors.textPrimary};
    height: 20px;
    width: 20px;
    transition: fill 0.2s;

    &:hover {
      fill: ${(props) => props.theme.colors.textTertiary};
    }
  }
`;

import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: ${(props) => props.theme.colors.textPrimary};

  .swiper-pagination-bullet {
    transition-property: background-color, opacity;
    transition-duration: 0.2s;

    &:hover {
      opacity: 1;
      background-color: ${(props) => props.theme.colors.border};
    }
  }
  .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.border};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 64px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bgPrimary};
`;
